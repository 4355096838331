import React from "react";

import { QueryClientProvider as TanstackQueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { queryClient } from "utils/query_client";

interface QueryClientProviderProps {
    children: React.ReactNode,
}
export const QueryClientProvider = ({
    children,
}: QueryClientProviderProps) => {
    return (
        <TanstackQueryClientProvider client={queryClient}>
            {/* According to docs ReactQueryDevtools won't be included in production bundles (that includes anything we deploy anywhere):
            https://tanstack.com/query/latest/docs/framework/react/devtools */}
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" client={queryClient} />
            {children}
        </TanstackQueryClientProvider>
    );
};
