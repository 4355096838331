import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { AxiosError } from "axios";

import { t } from "./i18n";
import { emitNotification } from "./notification";

export const RETRY_COUNT = 3;
/**
 * The stale time for associated resources
 */
export const STALE_TIME_RESOURCES = 30 * 1000; // 30 seconds

// By default, let's disable all auto-fetching capabilities.
// Developers will enable those one by one if need be.
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            retryOnMount: false,
            retry: false,
        },
        mutations: {
            onError: (error: Error | AxiosError) => {
                if (error instanceof AxiosError && error.isAxiosError && error.response) {
                    emitNotification(
                        "error",
                        t("Ajax_Client_HttpError_With_Code", { code: error.response.status }),
                    );
                } else {
                    emitNotification("error", t("Ajax_Client_Error"));
                }
            },
        },
    },
});


const PERSIST_TIME = 1000 * 60 * 60 * 24;
export const localStorageQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: PERSIST_TIME,
            staleTime: STALE_TIME_RESOURCES,
        },
    },
});
export const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
});
persistQueryClient({
    queryClient: localStorageQueryClient,
    persister: localStoragePersister,
    maxAge: PERSIST_TIME,
});
